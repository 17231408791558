import { useEffect } from 'react';

function IntroductionComponent() {

    return (
        <center>
            <div className="introductionContainer" id='home'>
                <div className="textIntroduction">
                    <h1>Hola! I am</h1>
                    <h1>Oliver Garcia</h1>

                    <p>Mobile and Web developer from Texas, USA. Freshman College student with a passion for technology looking to grow its knowledge.</p>
                </div>
                <div className="picIntroduction">
                    <img src={require('../img/appleMe.png')} />
                </div>

            </div>

            <div class="typewriter">
                <p class="typing-erase">oliverg.working@gmail.com</p>
            </div>

            <center id='aboutMe'>
                <div className='aboutMeContainer'>
                    <div className='infoAboutMe'>
                        <h1>ABOUT ME</h1>
                        <div>
                            <img src={require('../img/profilePic.png')} />
                            <p>I am a college student at the University of Texas at El Paso (UTEP) pursuing a Bachelor of Science in Computer Science. With a solid knowledge in Python, Javascript, Java, and frameworks such as React JS, React Native, Angular, and Flask, as well as experience with databases such as MySQL and Firebase, I am prepared to face complex challenges and build useful and scalable technological solutions. My mission as a software engineer is to make more than a significant contribution to the advancement of technology and the well-being of humanity.</p>
                        </div>
                    </div>

                    <div className='containerAboutMeSkills'>
                        <div className='pieceSkill'>
                            <div className='imgAboutMeSkills'>
                                <img src={require('../img/frontend.png')} />
                            </div>
                            <h2>Frontend</h2>
                            <div className='listSkills'>
                                <h3>HTML5</h3>
                                <h3>CSS3</h3>
                                <h3>JavaScript</h3>
                                <h3>ReactJS</h3>
                                <h3>React Native</h3>
                                <h3>Angular</h3>
                                <h3>Bootstrap</h3>
                            </div>
                        </div>

                        <div className='pieceSkill'>
                            <div className='imgAboutMeSkills'>
                                <img src={require('../img/skills.png')} />
                            </div>
                            <h2>Skills</h2>
                            <div className='listSkills'>
                                <h3>Data Structures</h3>
                                <h3>Algorithms</h3>
                                <h3>Git</h3>
                                <h3>API's</h3>
                                <h3>Figma</h3>
                                <h3>Problem-Solving</h3>
                                <h3>GitHub</h3>
                            </div>
                        </div>

                        <div className='pieceSkill'>
                            <div className='imgAboutMeSkills'>
                                <img src={require('../img/backend.png')} />
                            </div>
                            <h2>Backend</h2>
                            <div className='listSkills'>
                                <h3>MySQL</h3>
                                <h3>Python</h3>
                                <h3>Flask</h3>
                                <h3>Firebase</h3>
                            </div>
                        </div>
                    </div>


                    <hr className="invisibleHr" />
                    <hr className="invisibleHr" />
                    <hr className="invisibleHr" />


                    {/* <center>
                <div className="skillsIntroduction">
                    <div>
                        <h5>17 Year's Old</h5>

                    </div>

                    <div className='wBorderSkillsInt'>
                        <h5>Billengual</h5>

                    </div>

                    <div>
                        <h5>2+ Years</h5>
                    </div>
                </div>
            </center> */}


                </div>
            </center>

        </center>
    )
}

export default IntroductionComponent