import { useState } from 'react'
import ModalOtherProject from './modal/modalOtherProject';

/*Create a function what iterates a loop 5 times*/

function OtherProjects() {

    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [textInfo, setTextInfo] = useState('');
    const [img, setImg] = useState('');


    const activateFirstProject = () => {
        setTitle('Morse Code Translator')
        setTextInfo('This React Native project involved creating a mobile application that translates text to Morse code. The app provides an intuitive interface for inputting text and obtaining instant translations. It was designed to be user-friendly on mobile devices.')
        setImg('firstProject')
        setModal(true)
    }

    const activateSecondProject = () => {
        setTitle('Homes View')
        setTextInfo('This project involved developing a frontend system using Angular for users to explore houses and access key information such as location and property names. Additionally, a simulated form was designed to send data via HTTP requests to a live endpoint, supported by a JSON server.')
        setImg('secondProject')
        setModal(true)
    }


    return (
        <>
            <div class="otherProjectsContainer" id="otherProjects">
                <div class="contOtherProTop"></div>

                <div className="containerInfoOtherProjects">
                    <h1>Other Projects</h1>
                    <div className="containerActualProjects">

                        <div className="boxshadow" onClick={() => { activateFirstProject() }}>
                            <img className='imgOtherProjects' src={require('../img/projectPictures/morse-code-translator.jpg')} />
                            <h2>Morse Code Translator</h2>
                            <p>This React Native project involved creating a mobile application that translates text to Morse code. The app provides an intuitive interface for inputting text and obtaining instant translations. It was designed to be user-friendly on mobile devices.</p>
                            <button>More Info</button>

                            <div className='containerIconSkillsOtherProjects'>
                                <img src={require('../img/icons/reactJsIcon128.png')} />
                            </div>
                        </div>

                        <div className="boxshadow" onClick={() => { activateSecondProject() }}>
                            <div className='imgOtherProjects'>
                                <img src={require('../img/projectPictures/homes-view.png')} />
                            </div>
                            <h2>Homes View</h2>
                            <p>This project involved developing a frontend system using Angular for users to explore houses and access key information such as location and property names. Additionally, a simulated form was designed to send data via HTTP requests to a live endpoint, supported by a JSON server.</p>
                            <button>More Info</button>

                            <div className='containerIconSkillsOtherProjects'>
                                <img src={require('../img/icons/angularIcon128.png')} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ModalOtherProject modal={modal} setModal={setModal}
                title={title} textInfo={textInfo} img={img} />
        </>
    )
}

export default OtherProjects