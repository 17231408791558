import Carousel from 'react-bootstrap/Carousel';

function FirstProjectCarousel({ changeView, setChangeView, setModalSize }) {

  const changeToComputer = () => {
    setChangeView('Computer')
    setModalSize('lg')
  }

  const changeToMobile = () => {
    setChangeView('Mobile')
    setModalSize('sg')
  }

  return (
    <>
      {changeView == 'Mobile' ?
        <>
          <Carousel variant="dark">
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/mobilePictures/SignInScreen.png')}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/mobilePictures/HomeRutasScreen.png')}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/mobilePictures/RutasScreen.png')}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/mobilePictures/AccountsScreen.png')}
                alt="Fourth slide"
              />
            </Carousel.Item>
          </Carousel>
          <button style={{
            border: 'none', borderRadius: '10px', backgroundColor: '#025a7c',
            width: '60%', padding: '5px', fontFamily: 'SoraBold', color: 'white',
            fontSize: '18px'
          }}
            onClick={() => { changeToComputer() }}>Change View Computer
          </button>
        </>
        :
        <>
          <Carousel variant="dark">
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/computerPictures/RoutesDashboard.png')}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/computerPictures/RouteLiveExample.png')}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/computerPictures/CreateRoutes.png')}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/computerPictures/ReportsRoutes.png')}
                alt="Fourth slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img
                className="d-block w-100"
                src={require('../../img/daycareRoutes/computerPictures/SignInScreen.png')}
                alt="Fifth slide"
              />
            </Carousel.Item>
          </Carousel>
          <button style={{
            border: 'none', borderRadius: '10px', backgroundColor: '#025a7c',
            width: '50%', padding: '5px', fontFamily: 'SoraBold', color: 'white',
            fontSize: '18px'
          }}
            onClick={() => { changeToMobile() }}>Change View Mobile
          </button>
        </>

      }

    </>

  );
}

export default FirstProjectCarousel;