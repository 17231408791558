import { useState } from 'react'
import ModalMainProject from './modal/modalMainProject';

function MyWorkComponent() {
    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [textInfo, setTextInfo] = useState('');

    const activateFirstProject = () => {
        setTitle('Daycare-Routes Software Management')
        setTextInfo('The web-based control panel enables staff to efficiently manage routes, while also allowing for the export of weekly reports and real-time route tracking. This control panel simplifies the modification of existing routes and the creation of new ones. Furthermore, the mobile application enhances route coordination and facilitates check-in/check-out processes.')
        setModal(true)
    }

    const activateSecondProject = () => {
        setTitle('Face-Recognition Attendance Software')
        setTextInfo('The facial recognition attendance system simplifies attendance tracking for companies and organizations. This software streamlines attendance recording and enhances security and accuracy by using facial recognition technology. With an intuitive user interface, our users can easily register employees, making the process simple and secure.')
        setModal(true)
    }

    return (
        <>
            <div class="workContainer" id='myWork'>
                <h1>MY WORK</h1>
                <p>Projects I have been working since I started learning!</p>

                <center>
                    <div className="projectContainer projectContainerFirst">
                        <div className='picturesFirstProject' onClick={() => { activateFirstProject() }}>
                            <h2 id='viewProject'>View</h2>
                            <img class="pictureDesktop" src={require('../img/projectPictures/daycare-routes-web.png')} />
                            <img class="pictureMobile" src={require('../img/projectPictures/daycare-routes-mobile.png')} />
                        </div>

                        <div className='infoFirstProject'>
                            <h2>Daycare-Routes Software Management</h2>
                            <h2>(Website and Mobile App)</h2>

                            <p>The web-based control panel enables staff to efficiently manage routes, while also allowing for the export of weekly reports and real-time route tracking. This control panel simplifies the modification of existing routes and the creation of new ones. Furthermore, the mobile application enhances route coordination and facilitates check-in/check-out processes.</p>

                            <div className='skillsFirstProject'>
                                <h3>React JS</h3>
                                <h3>React Native</h3>
                                <h3>Firebase</h3>
                                <h3>Bootstrap</h3>
                            </div>
                        </div>

                    </div>
                </center>

                <hr className='invisibleHr' />
                <hr className='invisibleHr' />

                <center>
                    <div className="projectContainer projectContainerFirst">
                        <div className='picturesFirstProject' onClick={() => { activateSecondProject() }}>
                            <h2 id='viewProject'>View</h2>
                            <img class="pictureDesktop" src={require('../img/projectPictures/face-recognition.png')} />
                        </div>

                        <div className='infoFirstProject'>
                            <h2>Face-Recognition Attendance Software</h2>
                            <h2>(Website)</h2>

                            <p>The facial recognition attendance system simplifies attendance tracking for companies and organizations. This software streamlines attendance recording and enhances security and accuracy by using facial recognition technology. With an intuitive user interface, our users can easily register employees, making the process simple and secure.</p>

                            <div className='skillsFirstProject'>
                                <h3>MySQL</h3>
                                <h3>Python (Flask)</h3>
                                <h3>HTML5</h3>
                                <h3>CSS3</h3>
                                <h3>JavaScript</h3>
                            </div>
                        </div>
                    </div>
                </center>

            </div>

            <ModalMainProject modal={modal} setModal={setModal}
                title={title} textInfo={textInfo} />
        </>
    )
}

export default MyWorkComponent